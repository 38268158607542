$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "~roboto-fontface/css/roboto/roboto-fontface.css";

$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/' !default;
@import '~material-design-icons-iconfont/src/material-design-icons';

@import 'sass/color';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme);

/* You can add global styles to this file, and also import other style files */
html, body {
    height: 100vh;
    height: -webkit-fill-available;
    overflow: hidden;
    position: relative;
    background-color: mat-color($primary);
}

body {
    margin: 0;
    font-family: Roboto,
    "Helvetica Neue", sans-serif;
}
